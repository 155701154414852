<script setup>
import CustomFieldValue from "../customFields/CustomFieldValue.vue";
import startupsService from "@/services/startups.service";
import trackersCompaniesService from "../../services/trackers-companies.service";
import {ref, provide} from "vue";

const props = defineProps(['columnData', 'rowId', 'addData']);
const emit = defineEmits(['diligenceFieldChanged']);
const customFieldValue = ref(null);

function valueChanged() {
    if (props.columnData.field.main_type === 'tracker_diligence') {
        emit('diligenceFieldChanged');
    }
}
function saveCustomValue (fieldId, newValue) {
    if (props.columnData.field.main_type === 'tracker_diligence') {
        return trackersCompaniesService.saveDiligenceValues(props.addData.trackerId, props.rowId, fieldId, {newValue});
    } else {
        return startupsService.saveCustomValue(props.rowId, fieldId, {newValue});
    }
}

function saveCustomFile (fieldId, data) {
    if (props.columnData.field.main_type === 'tracker_diligence') {
        return trackersCompaniesService.saveDiligenceFiles(props.addData.trackerId, props.rowId, fieldId, data);
    } else {
        return startupsService.saveCustomFiles(props.rowId, fieldId, data);
    }
}

provide('saveCustomValue', saveCustomValue);
provide('saveCustomFile', saveCustomFile);

</script>

<template>
    <CustomFieldValue
        ref="customFieldValue"
        :field="columnData.field"
        :editable="columnData.field.can_edit"
        :init-value="columnData.value"
        :from-table="true"
        v-on:valueChanged="valueChanged"
    />
</template>

<style scoped>

</style>
